<template>
  <div class="space-y-10">
    <asom-card>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-form-field label="Date">
          <asom-input-date-range v-model="filters.dateRange" />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="loadData" />
      </template>
    </asom-card>
    <div class="w-full text-center" v-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-client-table
      v-else
      :columns="[
        'no',
        'officer',
        'station',
        'planDate',
        'planStart',
        'planEnd',
        'hours',
        'dws',
        'actions',
      ]"
      :data="rosters"
      :sortableColumns="[
        'officer',
        'station',
        'planDate',
        'planStart',
        'planEnd',
        'hours',
        'dws',
      ]"
      tableClasses="tbl_StationRosters"
      :thClasses="tableWithCustomClasses.thClasses"
      :tdClasses="tableWithCustomClasses.tdClasses"
    >
      <template v-slot:header_planDate class="etrewr">Plan Date</template>
      <template v-slot:header_planStart>Plan Start</template>
      <template v-slot:header_planEnd>Plan End</template>
      <template v-slot:header_dwsText>dws</template>
      <template v-slot:no="scopedSlots">
        {{ scopedSlots.rowIndex + 1 }}
      </template>
      <template v-slot:station="scopedSlots">
        {{
          scopedSlots.rowData.station && scopedSlots.rowData.station.stationName
        }}
      </template>
      <template v-slot:actions="scopedSlots">
        <div class="flex flex-col gap-1 w-48">
          <asom-button
            text="Edit"
            size="xs"
            @click="
              $router.push({
                name: 'Edit Roster',
                params: { rosterId: scopedSlots.rowData.rosterId },
              })
            "
          />
          <asom-button
            v-if="
              !['REST', 'OFF'].includes(scopedSlots.rowData.dws) &&
                canMarkUnavailability
            "
            text="Mark Unavailable"
            variant="error"
            size="xs"
            @click="
              $router.push({
                name: 'Staff Unavailability',
                params: { rosterId: scopedSlots.rowData.rosterId },
              })
            "
          />
        </div>
      </template>
    </asom-client-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import moment from "moment";
import { getRosterList } from "@/services/manpower.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";

export default {
  name: "Rosters",
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().toDate(),
          end: moment()
            .add(1, "weeks")
            .toDate(),
        },
      },
      rosters: [],
      skip: 0,
      isLoading: false,
      error: null,
      tableWithCustomClasses: {
        thClasses: {
          no: "justify-center",
          column_2: ["bg-blue-100", "font-normal"],
        },
        tdClasses: {
          column_1: {
            "font-bold": () => true,
            "bg-red-200": (rowData, rowIndex) => rowIndex % 2 == 0,
            "bg-red-100": (rowData, rowIndex) => rowIndex % 2 == 1,
          },
          column_2: {
            "bg-blue-100": (rowData) => rowData.column_2.length > 10,
          },
        },
      },
    };
  },
  watch: {
    "filters.dateRange": function(newValue) {
      if (newValue) {
        this.loadData();
      }
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      canMarkUnavailability: "auth/canMarkUnavailability",
    }),
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["dateFrom"] = parseDateTime(this.filters.dateRange.start);
        params["dateTo"] = parseDateTime(this.filters.dateRange.end);
      }
      params["stationId"] = get(
        this.$store,
        "state.auth.station.stationId",
        null
      );
      params["lineId"] = get(this.$store, "state.auth.line.lineId", null);
      return params;
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      const resp = await getRosterList({
        skip: this.skip,
        ...this.queryParams,
      });
      if (resp.success) {
        this.rosters = get(resp, "payload.list", []);
        this.error = null;
        this.isLoading = false;
      } else {
        this.error = resp.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
    },
    resetFilters() {
      this.filters.dateRange = null;
      this.filters.station = null;
      this.filters.line = null;
      this.filters.officerIds = [];
    },
  },
};
</script>

<style lang="scss">
.tbl_StationRosters {
  thead th:first-child > div {
    @apply justify-center;
  }
  tbody td:first-child {
    @apply text-center;
  }
  tbody td:nth-child(2) {
    @apply font-semibold text-gray-900;
  }
}
</style>
